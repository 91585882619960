import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

import {io as ioClient} from 'socket.io-client';

export default defineStore('main',{
  state: () =>({
      ws: null,
      pin: useStorage('pincode', '') ,
      room: '',
      notFound: false,
      messages: {},
      highlights: {},
      highlight_ids: [],
      lastMessage: {},
      maxMessages: 700,
      channels: [],

      channelColors: useStorage('channelColors', {}),
      showRichText: useStorage('showRichText', true),
      showEmote: useStorage('showEmote', true),
      showCheer: useStorage('showCheer', false),
      showMembership: useStorage('showMembership', false),
      showRank: useStorage('showRank', false),
      showHighlight: useStorage('showHighlight', true),
      showAlwaysLastMessage: useStorage('showAlwaysLastMessage', false),
      makeUsernamesMoreReadable: useStorage('makeUsernamesMoreReadable', true),

      isListeningForHightlights: false,
      isListeningForMessages: false,
  }),
  getters: {
    lastHighlight: (state) => {
      if (state.highlight_ids.length === 0) return null;
      let lastId = state.highlight_ids[state.highlight_ids.length - 1];
      return state.highlights[lastId];
    },
  },
  actions: {
    reset() {
      if (this.ws != null && this.ws.connected) {
        this.ws.disconnect();
        this.$patch((state) => {
          state.ws = null;
          state.isListeningForHightlights = false;
          state.isListeningForMessages = false;
          state.notFound = false;
          state.messages = {};
          state.highlight_ids = [];
          state.highlights = {};
          state.channels = [];
        });
      }
    },
    initWS(room) {
      if (import.meta.env.SSR) {
        return new Error("Can't connect to WS on SSR");
      }
      if (this.ws != null && this.ws.connected) {
        return new Error ("WS instance is already active");
      }
      
      return new Promise((res, fail) => {
        const ws = new ioClient(import.meta.env.VITE_IO_HOST);
        this.$patch((state) => {
          state.ws = ws;
        });

        ws.connect();
        
        // ws.once('connect', ()=>{
        //   res();
        // });

        ws.on('connect', ()=>{
          if (!this.room) return;
          ws.emit('set_room', this.room);
        });

        ws.emit('init', room, (data) =>{
          this.$patch({room}); // Side effect

          if ('notFound' in data) {
            this.$patch({notFound: true});
            res();
            return;
          }

          console.log('init', data);

          this.$patch((state) => {
            state.channels = data.channels;
            state.messages = {};
            for (const msg of data.messages) {
              state.messages[msg.msgid] = msg;
            }
            if (data.messages.length > 0) {
              state.lastMessage = data.messages.slice(-1)[0];
            }

            if (data.highlight_ids) {
              state.highlight_ids = data.highlight_ids;
            }

            if (data.highlights) {
              for (const msg of data.highlights) {
                state.highlights[msg.msgid] = msg;
              }
            }
            
            res();
          });
        });

        ws.on('channels', (data) => {
          if (!data.channels || !Array.isArray(data.channels)) return;
          this.$patch((state) => {
            state.channels = data.channels;
          });
        });

        ws.on('highlight_ids', (data) =>{
          this.$patch((state) => {
            state.highlight_ids = data.ids;
          });
        });
        
        ws.on('remhighlight', (recv_msgid) => {
          this.$patch((state) => {
            state.highlight_ids = state.highlight_ids.filter(msgid => msgid != recv_msgid);
            if (recv_msgid in state.highlights)
              delete state.highlights[recv_msgid];
          });
        });

      });
    },
    listenForNewMessages() {
      if (typeof this.ws == 'undefined' || !this.ws.connected) return new Error('WS connection required to listen for messages');

      this.ws.on('message', (data) => {
        this.$patch((state) => {
          if (Object.keys(state.messages).length > state.maxMessages) {
            let toDel = Object.keys(state.messages).reverse().slice(state.maxMessages);
            toDel.forEach((key) => {
              delete state.messages[key];
            });
          }
          state.messages[data.msgid] = data;
          state.lastMessage = data;
        });
      });

      this.ws.on('remove', (data) => {
        if (!(data.msgid in this.messages)) return;
        this.$patch((state) => {
            delete state.messages[data.msgid];
          });
      });

      this.isListeningForMessages = true;
      return true;
    },
    listenForNewHighlights() {
      if (typeof this.ws == 'undefined' || !this.ws.connected) return new Error('WS connection required to listen for messages');

      this.ws.on('highlight', (data) => {
        this.$patch((state) => {
          state.highlight_ids.push(data.msgid);
          state.highlights[data.msgid] = data;
        });
      });
      this.isListeningForHightlights = true;
      return true;
    },
    stopListenNewHighlights() {
      if (typeof this.ws == 'undefined' || !this.ws.connected) return new Error('WS connection required to listen for messages');

      this.ws.off('highlight');
      this.isListeningForHightlights = false;
      return true;
    },
    getCurrentHighlights() {
      if (typeof this.ws == 'undefined' || !this.ws.connected) return new Error('WS connection required to listen for messages');
      if (!this.room) return;

      return new Promise((res, rej) => {
        this.ws.emit('get_highlights', this.room, (data) => {
          this.$patch((state) => {
            state.highlights = data.messages;
          });
          res();
        });
      });
    },
    addHighlight(msg) {
      this.ws.emit('highlight', this.room, msg, this.pin, (resp)=>{
        if (resp.status == 'failed') return;
        this.$patch((state) => {
          state.highlight_ids.push(msg.msgid);
          if (state.isListeningForHightlights)
            state.highlights[msg.msgid] = msg;
        });
      });
    },
    removeHighlight(msg) {
      this.ws.emit('remhighlight', this.room, msg, this.pin, (resp)=> {
        if(resp.status == 'failed') return;
        this.$patch((state) => {
          state.highlight_ids = state.highlight_ids.filter(msgid => msgid != msg.msgid);
          if (msg.msgid in state.highlights)
            delete state.highlights[msg.msgid];
        });
      });
    },
    messagesLen() {
      return Object.keys(this.messages).length;
    },
    changeChannels(channels) {
      if (!Array.isArray(channels)) return;
      return this.ws.emit('channels', this.room, channels, this.pin);
    }
  }
})
