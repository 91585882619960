<script lang="ts" setup>
import router from '../router';
import { RouterLink } from "vue-router";
import { useTitle } from '@vueuse/core'
import useStore from '../stores/main';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {ref, watchEffect} from 'vue';
import axios from 'axios';
import type { Ref } from 'vue';

const store = useStore();

useTitle('Nieuwe chat groep maken');

const channels: Ref<Array<string>> = ref([]);
const chanInputEl: Ref<HTMLInputElement | null> = ref(null);
const chanInput = ref('');

const addChan = ()=>{
    if (chanInputEl.value === null || !chanInputEl.value.checkValidity()) return;

    channels.value.push(chanInput.value);
    chanInput.value = ''
};

const createGroup = async () => {
    const {data} = await axios.post('/room', {
        channels: channels.value,
    });

    store.reset();

    store.pin = data.pin;
    store.room = data.name;

    router.push({name: 'room-name', params: { name: data.name } });
};

</script>

<template>
    <div class="mt-10 p-8 rounded-3xl drop-shadow-xl bg-white lg:w-2/5 w-fit mx-auto">
        <h1 class="text-xl font-bold my-3">Twitcher Chat</h1>
        <p>Volg chat van meerdere twitch kanalen tegelijk. <br> Vul een of meer twitch streamers in om te starten.</p>
        <p>Groepen worden automatisch opgeruimd bij inactiviteit.</p>

        <section class="flex gap-8">
            <div>
                <h3 class="text-l font-bold my-3">Recente kanalen</h3>
                <ul>
                    <li>antialiased</li>
                    <li>bbb</li>   
                    <li>ccc</li>
                    <li>dfddf</li>
                </ul>
            </div>

            <div class="">
                <h3 class="text-l font-bold my-3">Nieuwe chat groep</h3>
                <ul class="block my-3">
                    <li v-if="channels.length === 0" class="italic text-gray-600">Kies een kanaal om een groep te maken</li>
                    <li v-for="channel in channels">{{ channel }} <font-awesome-icon icon="times" class="float-right hover:cursor-pointer" @click="channels = channels.filter(val=> val!=channel)"></font-awesome-icon> </li>
                </ul>
                <div>
                    <input pattern="^[a-zA-Z0-9][a-zA-Z0-9_]{0,24}$" required type="text" class="border-2 border-emerald-700 p-2 mr-2" placeholder="Bintjeeeh" v-model="chanInput" @keydown.enter="addChan" ref="chanInputEl">
                    <button type="button" class="rounded p-2 bg-emerald-700 text-white" @click="addChan">+</button>
                </div>
                <button
                    type="button"
                    class=" py-2 px-4 my-3 rounded" 
                    :class="{'bg-purple-200': channels.length <1, 'text-gray-400': channels.length < 1, 'bg-purple-700': channels.length >0, 'text-gray-100': channels.length >0, }" 
                    :disabled="channels.length === 0"
                    @click="createGroup">Creëer groep</button>
            </div>
        </section>

        <!-- <p class="my-2 hover:text-cyan-900">
            <router-link :to="{name: 'room-name'}">&raquo; Chat berichten</router-link>
        </p>
        <p class="my-2 hover:text-cyan-900">
            <router-link :to="{name: 'room-name-highlight'}">&raquo; Highlights</router-link>
        </p> -->
    </div>
</template>