import { createApp } from 'vue'
import router from './router';
import App from './App.vue'

import VueTippy from 'vue-tippy'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createPinia } from 'pinia'

// import 'floating-vue/dist/style.css'
import 'tippy.js/dist/tippy.css'
import './styles/index.css'


import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faGear, faMessage, faTimes, faPlay, faCrown, faStar, faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { faMessages, faMessageExclamation, faMessageDollar, faMessageDots, faMessageSlash, faMessageLines, faSwords, faUser, faUserCrown} from '@fortawesome/pro-solid-svg-icons';

library.add(faTimes, faGear, faMessage, faPlay, faCrown, faStar, faCircleMinus, faCirclePlus);
library.add(faMessages, faMessageExclamation,faMessageDollar, faMessageDots, faMessageSlash, faMessageLines, faSwords, faUser, faUserCrown);

const vueApp = createApp(App)
.use(router)
.use(createPinia())
.use(VueTippy, {
    defaultProps: { placement: 'top' },
  })
.use(FontAwesomeIcon);


if (process.env.NODE_ENV === 'development') {
  // This crashes vue-devtools. Soo usefull ugh
  // vueApp.config.performance = true;
}

vueApp.mount('#app')
